@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendatd';
  src: url('../assets/font/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendatd-blod';
  src: url('../assets/font/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendatd-semi';
  src: url('../assets/font/Pretendard-SemiBold.woff') format('woff');
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

.box {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.calendar {
  background: url(../assets/icon/calendar.png) no-repeat;
}

.setting {
  background: url(../assets/icon/setting.png) no-repeat;
}

.question {
  background: url(../assets/icon/question.png) no-repeat;
}

.close {
  background: url(../assets/icon/close.svg) no-repeat;
}

p,
div,
button {
  font-family: 'Pretendatd';
}

.modal {
  width: 100%;
  height: 100%;
  background-color: rgba(48, 48, 48, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
}

.loading span {
  position: relative;
  display: inline-block;
  letter-spacing: -0.8px;
  animation: txtup 1.5s infinite;
  -webkit-animation: txtup 1.5s infinite;
  -ms-animation: txtup 1.5s infinite;
  -moz-animation: txtup 1.5s infinite;
}
.loading span:nth-of-type(1) {
  animation-delay: 0.1s;
}
.loading span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  animation-delay: 0.3s;
}
.loading span:nth-of-type(4) {
  animation-delay: 0.4s;
}
.loading span:nth-of-type(5) {
  animation-delay: 0.5s;
}
.loading span:nth-of-type(6) {
  animation-delay: 0.6s;
}
.loading span:nth-of-type(7) {
  animation-delay: 0.7s;
}
.loading span:nth-of-type(8) {
  animation-delay: 0.8s;
}
.loading span:nth-of-type(9) {
  animation-delay: 0.9s;
}
@-webkit-keyframes txtup {
  0% {
    top: 0;
  }
  20% {
    top: -0.2rem;
  }
  40% {
    top: 0;
  }
  60% {
    top: 0;
  }
  80% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
@keyframes txtup {
  0% {
    top: 0;
  }
  20% {
    top: -0.2rem;
  }
  40% {
    top: 0;
  }
  60% {
    top: 0;
  }
  80% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
