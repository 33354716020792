/* ---------------------------------------- common ---------------------------------------------- */

:root {
  /* green - #1a3a20 / yellow - #F5CD21*/
  --brand-color: #1a3a20;
  /* green - #ffffff / yellow - #231F20 */
  --font-color: #ffffff;

  --black-color: #231f20;
  --white-color: #ffffff;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.brand-color {
  background-color: var(--brand-color);
  /* height: 100%; */
}

.font-color {
  color: var(--font-color);
}

.font-blod {
  font-family: Pretendatd-blod;
}

.font-semi {
  font-family: Pretendatd-semi;
}

.wh-24 {
  width: 24px;
  height: 24px;
}

.wh-36 {
  width: 36px;
  height: 36px;
}

.wh-48 {
  width: 48px;
  height: 48px;
}

.wh-120 {
  width: 120px;
  height: 120px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-18 {
  font-size: 18px;
}

/* ---------------------------------------- content ---------------------------------------------- */

.logo {
  width: 240px;
  height: 85px;
  background: url('../assets/logo/metsakuur.png') no-repeat;
  background-size: 100%;
}

.back-content {
  width: 375px;
  max-width: 479px;
  padding: 0px 24px 40px 24px;
}

.back-logo {
  width: 100%;
  height: 80px;
  background: url('../assets/logo/metsakuur3.png') no-repeat right;
  background-size: 80px;
}

.link {
  weight: 312px;
  height: 136px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px 24px;
}

.company {
  display: flex;
  width: 100%;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px dotted #cacaca;
}

.link-logo {
  background: url('../assets/logo/roundMetsakuur.png') no-repeat;
  background-size: cover;
}

.company-name {
  color: #333333;
}

.link-icon {
  background: url('../assets/icon/link.png') no-repeat;
}

.move a {
  color: #454545;
}

.arrow-icon {
  background: url('../assets/icon/arrow-right.png') no-repeat;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 400px;
  background-color: var(--white-color);
  border-radius: 16px;
  padding: 16px 24px;
}

.info div label {
  font-size: 14px;
  color: #454545;
}

.info div div:not(.address) {
  font-family: Pretendatd-blod;
  font-size: 16px;
  color: var(--black-color);
}

.address {
  color: var(--black-color);
}

.icon-group {
  position: fixed;
  width: inherit;
  bottom: 16px;
  padding-right: 48px;
}

.group {
  background-color: var(--black-color);
  border-radius: 24px;
  border: 1px solid var(--black-color);
}

.qr {
  background: url('../assets/icon/qr.png') no-repeat;
}

.download {
  background: url('../assets/icon/download.png') no-repeat;
}

.phone-add {
  width: 128px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--black-color);
  border-radius: 24px;
  border: 1px solid var(--black-color);
}

.phone-icon {
  background: url('../assets/icon/phone-add.png') no-repeat;
}

/* ------------------------------------------ qr --------------------------------------- */
.qr-close {
  background: url('../assets/icon/close.png') no-repeat;
}

.qr-logo-circle {
  position: relative;
  background-color: var(--black-color);
  border-radius: 50%;
}

.qr-logo {
  width: 72px;
  height: 56px;
  position: absolute;
  background: url('../assets/logo/metsakuur3.png') no-repeat;
  background-size: 100%;
  top: 28%;
}

/* .qr-down-btn {
  background-color: var(--brand-color);
  width: 192px;
  height: 60px;
  color: var(--white-color);
  border-radius: 16px;
} */

@media screen and (min-height: 907px) {
  .brand-color {
    height: 100%;
  }
}

/* 모바일 가로 & 테블릿 세로 (해상도 480px ~ 767px)*/
@media screen and (min-width: 480px) and (max-width: 767px) {
}

/* 모바일 세로 (해상도 ~ 479px)*/
@media screen and (max-width: 479px) {
}
