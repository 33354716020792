.card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a3a20;
}

.inside {
  position: relative;
  border-radius: 10px;
  width: 220px;
  height: 300px;
  background-color: whitesmoke;
  box-shadow: 1px 1px 12px #000;
  perspective: 2000px;
  display: flex;
  color: #000;
}

.cover {
  position: absolute;
  background-color: #1a3a20;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  border-radius: 10px;
  transform-origin: 0;
  box-shadow: 1px 1px 12px #000;
  display: flex;
  justify-content: flex-end;
}

.open {
  transition: all 1.8s;
  transform: rotatey(-150deg);
}

/* .inside:hover .cover {
  transition: all 1.8s;
  transform: rotatey(-150deg);
} */

.detail {
  width: 100%;
  height: 100%;
  padding: 36px 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px #000;
}

.image {
  background-image: url('../assets/logo/metsakuur3.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 28px 16px;
}

.info-card {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: var(--white-color);
  border-radius: 16px;
  padding: 12px 12px 4px;
}

.info-card div label {
  font-size: 14px;
  color: #454545;
}

.url-logo {
  background-image: url('../assets/logo/urlLogo.png');
  background-repeat: no-repeat;
}

/* 노트북 & 테블릿 가로 (해상도 1024px ~ 1279px)*/
@media screen and (min-width: 1024px) and (max-width: 1279px) {
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
}

/* 모바일 가로 & 테블릿 세로 (해상도 480px ~ 767px)*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .inside {
    width: 624px;
    height: 316px;
  }
}

/* 모바일 세로 (해상도 ~ 479px)*/
@media screen and (max-width: 479px) {
  .inside {
    width: 316px;
    height: 624px;
  }
}
