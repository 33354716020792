.parent {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
}

.bar {
  top: 0;
  /* left: -70px;  */
  /* left: 100%; */
  width: 70px;
  height: 100%;
  background-color: #1a3a20;
  /* animation: moveBar 5s linear forwards; */
}

/* .flag {
  position: absolute;
  top: 20px; 
  left: -30px; 
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #fff; 
  animation: moveFlag 5s linear forwards;
  background: url('../assets/logo/metsakuur.png') no-repeat;
} */
.metsa-logo {
  width: 100%;
  height: 80px;
  background: url('../assets/logo/Metsakuur4.png') no-repeat right;
  margin: 42px 0px 0px -54px;
}
.card-content {
  right: 20px;
  padding: 32px 32px 0px 0px;
}

.text {
  display: inline-block;
  color: #333;
  opacity: 0;
  transition: opacity 0.5s;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hidden {
  opacity: 0;
}

.shown {
  opacity: 1;
}

@keyframes moveBar {
  0% {
    left: -70px; /* 막대바 시작 위치 */
  }
  100% {
    left: calc(100% - 70px); /* 막대바 끝 위치 */
  }
}

.profile-card {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.profile-social-links {
  background: #1a3a20;
  height: 100%;
  width: 70px;
  position: absolute;
  top: 0px;
  right: 0px;
  list-style: none;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

li {
  height: 25%;
}

li img {
  padding: 10px;
  width: 40px;
  display: block;
  margin: 0 auto;
  transform: translate(0%, 40%);
  -webkit-transform: translate(0%, 40%);
}

li img:hover {
  border: 1px solid white;
  border-radius: 1px;
  padding: 11px;
  transition: all 0.3s ease;
}

.test div label {
  font-size: 14px;
  color: #454545;
}

.test div div:not(.address) {
  font-family: Pretendatd-blod;
  font-size: 16px;
  color: var(--black-color);
}

.address {
  color: var(--black-color);
}
